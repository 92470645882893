.credit-card-form {
    label {
        font-size: 14px;
    }
    .form-control {
        background: #CBCBCB;
        border: 1px solid #BEBEBE;
        border-radius: 5px;
        height: 40px;
        line-height: 40px;
    }
    .form-submit {
        background: #EA9900;
        border: 0;
        height: 34px;
        line-height: 34px;
        color: #fff;
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        width: 100%;
        border-radius: 5px;
    }

    .form-wrap {
        position: relative;

        .info-label {
            position: absolute;
            right: 10px;
            top: 8px;
            opacity: .4;
        }
    }
}

.infoBox {
    padding: 15px;
    background: #cbcbcb;
    border: 1px solid #bebebe;
    border-radius: 5px;
    margin-top: 12px;
    margin-bottom: 10px;

    p {
        padding: 0;
        margin: 0;
    }

    &-header {
        p {
            font-size: 16px;

        }
        border-bottom: 1px solid #bebebe;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    &-body {
        p {
            font-weight: bold;
        }
    }
}

.change-payment {

    &--type {
        display: flex;
        margin-top: 10px;
        padding: 10px 0;
        width: 100%;
        align-items: center;
        justify-content: center;

        div {
            width: 100%;
        }

        h3 {
            font-size: 20px;
            font-weight: bold;
            padding: 0px;
            margin: 0;
            color: #707070;
        }

        a {
            text-transform: uppercase;
            font-size: 11px;
            color: #333;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--type.active {
        background: #EA9900;
        color: #fff;
        padding: 15px;
        margin-top: 15px;
        border-radius: 5px;

        h3, a, small {
            color: #fff;
        }
    }

}