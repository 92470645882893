.box-cookies {

}



.box-cookies.hide {
    display: none !important;
  }
  
  .box-cookies {
    position: fixed;
    width: 90%;
    margin-left: 5%;
    bottom: 30px;
    left: 0;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.25);
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s linear;
  }

  .msg-cookies {
      color: #000;
      margin: 0;
  }
  
  .box-cookies .btn-cookies {
    width: 100%;
    background: #EA9900;
    border: 1px solid #EA9900;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    max-width: 150px;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 600px) {
    .box-cookies {
      flex-direction: column;
    }
  }

  .hideEffect {
      animation: hideEffectAnimation 1s;
      bottom: -100%;
  }

  @-webkit-keyframes hideEffectAnimation {
      0% {
        bottom: 30px;
      }
      10% {
        bottom: 40px;
      }
      100% {
        bottom: -100%;
      }
  }

  @keyframes hideEffectAnimation {
    0% {
      bottom: 30px;
    }
    10% {
      bottom: 40px;
    }
    100% {
      bottom: -100%;
    }
}