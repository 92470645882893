.packlist {
    display: flex;
    max-width: 960px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-top: 40px;

    &_item {
        width: 28%;
        margin: 0 20px;
        text-align: center;
        padding: 20px;
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        margin-bottom: 50px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);

        a {
            color: #fff;
            cursor: pointer;

            &:hover, &:active, &:focus {
                color: #fff;
                text-decoration: none;
            }
        }

        img {
            margin: 0;
            padding: 0;
            max-width: 100%;
            max-height: 80px;
        }

        @media screen and (max-width: 768px){
            flex-direction: column;
        }
    }
}

.min-page-size {
    min-height: calc(100vh - 192px);
    padding: 0 10px;
    display: block;

    h2 {
        color: white;
    }
}