.header {
    background-color: #262626;
    width: 100%;
    height: 60px;
    align-items: center;
    display: flex;
    position: sticky;
    opacity: 0.9;
    top: 0px;
    z-index: 999;
    transition: all 0.3s linear;
}

.header.transparent {
    background-color: transparent !important;

    .logo {
        opacity: 0 !important;
        visibility: hidden;
    }
}

.header .center {
    position: absolute;
    left: 50%;
    margin-left: -90px;
}

.header .btn-menu {
    position: relative;
    z-index: 9;
    color: #fff;
    font-size: 24px;
    margin-right: 15px;
    text-align: center;
}

.header .btn-menu-mobile {
    position: relative;
    z-index: 9;
    color: #fff;
    font-size: 9px;
    margin-right: 15px;

    figure {
        margin-bottom: 5px;

        img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
        }
    }
}

.header a {
    color: white;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    margin: 0 15px;
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    align-items: center;
    font-size: 16px;
    transition: all 0.3s linear;

    p {
        transition: all 0.13s linear;
    }

    &:hover {

        p {
            border-bottom: 1px solid #fff;
            font-size: 18px;
        }
        color: #fff;
        text-decoration: none;
    }
}

.header a.loginLink {
    border: 1px solid #fff;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 12px;
    margin: 0 5px;
    text-transform: uppercase;
    
    opacity: 0;
    visibility: hidden;
}

.header a.loginLink.active {
    opacity: 1;
    visibility: visible;
}

.header a.testLink {
    border: 1px solid #EA9900;
    background: #EA9900;
    color: #fff;
    font-weight: bold;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 12px;
    margin: 0 5px;
    text-transform: uppercase;

    opacity: 0;
    visibility: hidden;
}

.header .testLink.active {
    opacity: 1;
    visibility: visible;
}

 .header a:hover {
  }

  .header img.icon {
      height: 14px;
      vertical-align: middle;
      display: inline;
  }

  .header img {
      display: inline;
      vertical-align: middle;
  }

  .header p {
      margin-left: 10px;
      vertical-align: middle;
      display: inline;
      line-height: normal;
  }

  .header-links {
      min-width: 200px;

      @media (max-width: 768px){
          min-width: 100px;
      }
  }

.profile-link {

    // position: absolute;
    // right: 0;
    // top: 0;
    flex-direction: column;

    &-item {
        line-height: 60px;
    }

    .itens {
        // padding-bottom: 10px;
        width: 100%;
        margin: 0 10px;
        // padding-top: 10px;
        overflow: hidden;
        max-height: 0;
        transition: all 0.3s linear;
        opacity: 0;

        a, .link {
            font-weight: 300;
            padding: 10px 0;
            font-size: 13px;
            cursor: pointer;
            color: #ccc;
            display: inline-block;
            margin: 0;
            width: 100%;
        }
        

    }

    &:hover {

        background: #666;
        box-shadow: 0px 0px 0px 1px #ccc;

        .itens {
            max-height: 300px;
            border-top: 1px solid #777;
            opacity: 1;
        }
    }
    
    img {
        border-radius: 50%;
    }

    figure {
        // border-radius: 50%;
        // background: #D8D8D8;
        width: 40px;
        height: 40px;
        display: flex;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 768px){
    
    .header a.testLink {
        display: none;
    }
    
}