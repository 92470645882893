.VideosList {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    .video {
        a {
            text-decoration: none;
        }
        p {
            padding-top: 5px;
            font-size: 12px;
            color: #CCCCCC;
        }
        p.remove {
            cursor: pointer;
        }

        transition: all 0.3s linear;

        img.img-fluid {
            box-shadow: 0px 0px 15px rgba(0,0,0,.55);
            border: 1px solid #707070;
        }

        &:hover {
            transform: scale(1.15);

        }
    }

    &-remove {    
        padding: 10px 0 0;
        position: relative;
        margin: 0;
        width: 100%;
        color: #5e5e5e !important;
        font-weight: 700 !important;
        font-size: 11px !important;
        text-transform: uppercase;
        cursor: pointer;

        img {
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 3px;
        }
    }

}