.video-page {
    min-height: calc(100vh - 192px);
}

.player-box {
    padding-top: 40px 15px;
    margin: 0 auto;
    max-width: 880px;
    color:  #CCC;

    .video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.player-center {
    max-width: 880px;
    margin: 0 auto;
    display: block;
    width: 100%;
}

.player-title {
    color: #CCC;
    text-align: left;
    font-weight: bold;
    padding-top: 10px;
    width: 100%;
    display: block;
}

.player-description {
    color:  #CCC;
    padding-bottom: 25px;
    text-align: left;
    width: 100%;
    display: block;
}

.player-time {
    color:  #CCC;
    padding-left: 5px;
    padding-right: 10px;
    margin-right: 10px;
    font-size: 12px;
    float: left;
}

.player-add {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #fff;
    font-weight: 300;
    font-size: 14px;

    img {
        margin-right: 5px;
    }

    p {
        padding: 10px 0 0;
        position: relative;
        margin: 0;
        width: 100%;
        color: #5e5e5e;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        cursor: pointer;
    }
}


