.index-box {
    height: 9000px;
}

.home-box {
    overflow-x: hidden;
}

.btn-add {
    color: #2b2b2b;
    outline: none;
}

.btn-add:focus {
    outline: none;
}