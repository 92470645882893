.contact-box {
    a {
        color: #707070;
        transition: all 0.3s linear;

        &:hover {
            color: #EA9900;
            text-decoration: none;
        }
    }
}