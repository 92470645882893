.login-box {
    padding-left: 5%;
    padding-right: 5%;
}

.login-form {
    padding-bottom: 50px;
    padding-top: 50px;
    width: 100%;
    max-width: 400px;
    background-color: #D8D8D8;
    border-radius: 10px;
    margin: 50px auto;
    box-sizing: border-box;

    a {
        color: #ea9900;
        // text-decoration: none;
    }
}

.login-form-title {
    padding-bottom: 20px;
    color: #707070;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    display: block;
}

.login-form-error-message {
    text-align: center;
    color: #dc3545;
    font-size: 10px;
    font-weight: bold;
    margin: auto;
    display: block;
}

.login-form-input {
    display: block;
    margin: auto;
    margin-bottom: 20px;
    background-color: #CBCBCB;
    color: #707070;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.login-form-submit {
    display: block;
    margin: auto;
    margin-top: 50px;
    background-color: #EA9900;
    color:white;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.login-sign-in {
    color: #707070;
    font-size: 12px;
    text-align: center;
    padding-top: 30px;
}

.login-link-sign-in {
    color: #707070;
    font-size: 12px;
    text-align: center;
    padding-top: 30px;
    text-decoration: underline;
}

.login-page {
    min-height: calc(100vh - 242px);
    padding: 0 10px;
    display: block;
}

.login-mail-error {
    background: rgba(0,0,0,0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;

    .content {
        background: #444;
        width: 450px;
        border-radius: 10px;
        padding: 30px 40px;
        position: relative;
        z-index: 1001;
        color: #fff;

        h2 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        p {
            font-weight: 14px;
        }

        .btn-area {
            display: flex;
            justify-content: space-between;
            padding-top: 40px;

            a {
                display: inline-block;
                color: #fff;
                font-weight: bold;
                border: 1px solid #fff;
                border-radius: 5px;
                padding: 5px 10px;
                width: 100%;
                text-align: center;
                transition: 0.3s linear all;

                &:hover {
                    text-decoration: none;
                    opacity: .6;
                }
            }
            a.primary {
                background: #e89824;
                border-color: #e89824;
            }
        }

        @media (max-width: 600px) {
            width: calc(100% - 40px);
        }
    }
}