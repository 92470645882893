.img-round {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    margin:0 auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    overflow: hidden; 

    img {
        max-width: 100%;
    }
}

#changeAvatar {
    display: none;
}

.changeAvatar {
    border: 2px dashed #ccc;
    padding: 20px;
    background: #eee;
    width: 100%;
    height: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #444;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: all 0.3s linear;

    &:hover {
        background: #ddd;
    }

    .imgPreview {
        position: absolute;
        width: 90%;
        left: 5%;
        top: 5%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        img {
            box-shadow: 0px 0px 10px rgba(0,0,0,.6);
            height: 100%;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}