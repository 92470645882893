.LegalTerms {

    .title {
        h1 {
            color: #fff;
            font-weight: bold;
            margin: 1em 0;
        }
    }

    .nav {
        a {
            color: #fff;
            opacity: .6;
            border: 0;
            padding-left: 0;
            padding-right: 25px;
        }

        .nav-link.active {
            background: transparent;
            border: 0;
            color: #fff;
            opacity: 1;
        }
    }

    .tab-content {
        padding: 30px 0 70px 0;
        color: #fff;
        font-size: 14px;
    }

}