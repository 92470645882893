.carousel-card {
    border-radius: 5px;
    display: block !important;
    max-width: 95%;
    margin: 7px auto 15px 0;
    transition: transform .7s;

    p {
        padding: 10px 0 0;
        position: relative;
        margin: 0;
        width: 100%;
        // text-align: right;
        color: #5e5e5e;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        cursor: pointer;

        img {
            display: inline-block;
            position: relative;
            top: -2px;
            margin-right: 3px;
        }
    }

    figure {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;

        i, svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            color: #fff;
            fill: #fff;
            opacity: .8;
            width: 100%;
            height: 60%;
            margin-top: 10%;
        }        
    }
}

.img-carousel {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    box-shadow: 0px 0px 15px rgba(0,0,0,.55)
}

.carousel-card:hover {
    transform: scale(1.15);
}

.categories-carousel {
    width: 95%;
    margin: 0px auto;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 20px;
}

.carousel-infos {
    min-height:97px;
    display: block;
}

.category-title {
    padding-left: 10px;
    font-weight: bold;
    color:#CCCCCC;
    margin-bottom: 0;
}

.carousel-description,
.carousel-title {
    margin: 0px;
    font-size: 12px;
    color: #cccccc;
    width: 100%;
    
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.carousel-title {
    font-weight: bold;
    margin: 10px 0 5px 0;
         
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.slick-list {
    overflow: visible !important
}

.slick-prev {
    z-index: 998;
    transform: rotate(180deg); 
    
    @media screen and (max-width: 768px){
        left: 0;
    }
}

.slick-next,
.slick-prev {

    &:before {
        display: none;
    }

    opacity: .3;
    width: 34px;
    margin-top: -50px;
    height: 57px;
    background: url(./arrow.png) center no-repeat;
    background-size: cover;
    transition: all 0.3s linear;

    &:hover,
    &:focus {
        opacity: .7;
        background: url(./arrow.png) center no-repeat;
    }
    
    @media screen and (max-width: 768px){
        width: 24px;
        height: 39px;
    }

}

.slick-next {
    transform: inherit !important;
    
    @media screen and (max-width: 768px){
        right: 0;
    }
}

$lineheight:14px;
$height: $lineheight * 3;
$ellipsiswidth: 3em !default;

.ellipsis {
    overflow: hidden;
    height: $height;
    line-height: $lineheight; }
  
  .ellipsis:before {
    content:"";
    float: left;
    width: 5px; height: $height; }
  
  .ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px; }		
  
  .ellipsis:after {
    content: "\02026";
  
    float: right; 
    position: relative;
    top: -$lineheight; 
    left: 100%; 
    width: $ellipsiswidth; 
    margin-left: -$ellipsiswidth;
    padding-right: 5px;
  
    text-align: right;
    background: white;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white)
  }