@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  min-height: 100vh;
  font-family: 'Raleway', sans-serif !important;
}


.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .tab {
    // width: 50%;
    width: 100%;
    padding: 5px;
    
    &.active {
      border-bottom: 2px #000 solid;
    }
    &:focus {
      outline: none;
    }
  }
}

.link {
  color: #ea9900;
  text-decoration: none;

  &:hover {
    color: darken(#ea9900, 10);
    text-decoration: none;
  }
}

.page {
  min-height: calc(100vh - 193px);
  padding: 50px;
  color: #fff;
  overflow-x: hidden;
  
  h3 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #CCCCCC;
  }
}

.page-ops {
  min-height: calc(100vh - 193px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;

  h3 {
    font-weight: bold;
    margin: 25px 0;
  }
}

.error-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;

  .modal-content {
      padding: 20px;
      text-align: center;

      .close-btn {
          background: #EA9900;
          color: #fff;
          font-weight: bold;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
      }
  }
}

.login-form-reset {
  padding: 50px 0 0 0;
  background-color: transparent !important;
  width: 100%;
}

.light-button {
  width: 100%;
  display: block;
  font-size: 16px;
  color: #707070;
  border: 0;
  text-align: center;
}

.box-center {
  padding: 15px;
  max-width: 400px;
  width: 100%;
  background-color: #D8D8D8;
  border-radius: 10px;
  margin: 50px auto;
  box-sizing: border-box;
}

