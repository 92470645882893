.payment-form {

    p {
        font-size: 12px;
    }

    .form {
        label {
            font-size: 14px;
        }
    }

}

.gray-box {
    background: #CBCBCB;
    border: 1px solid #BEBEBE;
    padding: 5px;
    border-radius: 5px;
}

.react-dropdown-select {
    background: #EA9900A3;
    border: 1px solid #EA9900 !important;
    border-radius: 5px !important;
    padding: 6px 15px 4px 15px !important;
    height: 45px;
}

.react-dropdown-select.active {
    background: #EA9900;
}


.react-dropdown-select-input {
    margin-left: 0 !important;

    &::placeholder {
        color: #fff;
        font-weight: bold;
        font-size: 16px !important;
    }
}

.react-dropdown-select-content {
    color: #fff;
    font-weight: bold;
}

.react-dropdown-select-item-selected {
    color: #000 !important;
}

.react-dropdown-select-dropdown-handle {
    color: #fff !important; 

    svg {
        width: 32px !important;
        height: 32px !important;
    }
}

.payment-page {    
    min-height: calc(100vh - 242px);
    padding: 0 10px;
    display: block;
}