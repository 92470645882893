.pw-str {
    width: 100%;
    height: 15px;
    background: #cbcbcb;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 33%;
        left: 33%;
        height: 15px;
        z-index: 2;
    }

    
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
    }

    &.weak {
        &:after {
            background: #db7504;
            width: 33%;
        }
    }
    &.good {
        &:after {
            background: #db7504;
            width: 66%;
        }
    }
    &.strong {
        &:after {
            background: #db7504;
            width: 100%;
        }
    }
}

.pw-type {
    display: none;
}

.pw-str + .pw-type {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    div {
        text-align: center;
        font-size: 10px;
        padding-top: 6px;
        color: #000;
        font-weight: 300;
        flex: auto;
    }
}