.banner-carousel {
    width: 95%;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 20px;
}

.banner-carousel img {
    border-radius: 5px;
}

.carousel-control-prev,
.carousel-control-next {
    display: none;
}