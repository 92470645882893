.sidemenu {

    h2 {
        padding: 20px 20px 10px 20px;
    }

    li a {
        padding: 10px 20px;
        margin: 0;
        border-bottom: 1px solid #666;
        width: 100%;
    }
}