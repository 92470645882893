.footer {
    bottom: 0px;
    background-color: #262626;
    width: 100%;
    color: #fff;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    ul {
        list-style: none;
        margin-top: 10px;
        padding: 0;

        li {
            display: inline-block;
            padding: 0;

            a {
                font-size: 12px;
                font-weight: 100;
                color: #E8E8E8;
                padding: 5px 10px;
            }
        }
    }

    p {
        font-size: 11px;
        color: #B5B5B5;
        margin-top: 10px;
        text-align: center;
        max-width: 400px;
        width: 100%;
    }
}