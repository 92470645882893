.register-contract-box-pack {
    max-height: 400px;
    border: 1px solid #ccc;
    padding: 20px;
    font-size: 12px;
    line-height: 1.5;
    margin-top: 15px;
    color: #333;
}

.register-form-close {
    color: #333;
    background: transparent;
    border: none;
    line-height: 20px;
    padding: 0;
    font-size: 14px;
}

#toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
}
#toast-container {
    margin-top: 1rem;
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

#toast-container.toast-top-full-width>.toast {
    max-width: 570px;
}
#toast-container.toast-bottom-full-width>div, #toast-container.toast-top-full-width>div {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}
#toast-container>.toast-success {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==)!important;
}
#toast-container>.toast-success {
    background-color: #ea9900;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
}
#toast-container>.toast-error {
    background-color: darkred;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    padding: 15px !important;
}
@media (min-width: 481px) and (max-width: 768px){
    #toast-container>div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}
#toast-container>div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    -moz-box-shadow: 0 0 12px #999;
    -webkit-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    color: #fff;
    opacity: .8;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    filter: alpha(opacity=80);
}
#toast-container * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.toast-success {
    background-color: #51a351;
}
.toast {
    background-color: #030303;
}