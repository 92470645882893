.register-contract-box {
    max-height: 400px;
    border: 1px solid #ccc;
    padding: 20px;
    font-size: 12px;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 15px;
    color: #333;
}