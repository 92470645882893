.Landing {
    position: relative;

    &-btnMobile {
        position: fixed;
        bottom: -70px;
        width: 100%;
        left: 0;
        text-align: center;
        background: rgba(0,0,0,.55);
        height: 60px;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        transition: all 0.3s linear;

        a {
            background: #EA9900;
            border: 1px solid #EA9900;
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
            padding: 8px 15px;
            border-radius: 5px;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
        }

        @media (max-width: 768px){
            display: flex;
        }
    }
    &-btnMobile.show {
        bottom: 0;
    }

    &-static,
    &-reactivate {
        background: url('banner-landing.png');
        background-size: cover;
        width: 100vw;
        height: 100vh;
        top: -30px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;

        @media (max-width: 768px){
            background-size: 185%;
            background-repeat: no-repeat;
            background-position: 0 -90px;
            padding-top: 30px;
        }

        .landing-image {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 100%;
            z-index: -1;
        }

        .align-center {
            width: 1280px;
            
            @media (max-width: 1366px){
                width: 1180px;
            }
            @media (max-width: 1280px){
                width: 960px;
            }
            @media (max-width: 960px){
                width: 768px;
            }
            @media (max-width: 768px){
                width: 100%;
            }
        }
        
        .animatedScenario {
            width: 100%;
            // padding-left: 10vw;
            text-align: right;

            h2 {
                color: #CCCCCC;
                width: 100%;
                // max-width: 600px;
                font-weight: bold;
                font-size: 62px;
                line-height: 60px;
            }
            
            h3 {
                margin-top: 20px;
            }

            &-plans {
                max-width: 300px;
                width: 100%;
                float: right;

                h4 {
                    font-size: 24px;
                    margin: 0;
                }
                
                h2 {
                    font-size: 48px;
                    margin: 0;

                    small {
                        font-size: 32px;
                        font-weight: 100;
                    }
                }

                .btn {
                    width: 100%;
                    margin-top: 20px;
                    background: #EA9900;
                    border: 1px solid #EA9900;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 500;
                    padding: 15px 0;
                    font-size: 24px;

                    a {
                        color: #fff;
                    }

                    &:hover, &:active, &:focus {
                        background: darken(#EA9900, 15) !important;
                    }
                }
            }

            
            @media (max-width: 768px){
                text-align: center;

                img {
                    height: 70px;
                }

                h2 {
                    font-size: 22px;
                    padding: 0;
                    line-height: 22px;
                }
                h3 {
                    margin-top: 0;
                    padding: 0;
                    font-size: 16px;
                    line-height: 22px;
                }

                .animatedScenario-plans {
                    float: none;
                    margin: 0 auto;

                    h4 {
                        font-size: 14px;
                    }

                    .btn {
                        font-size: 22px;
                        padding: 10px 15px;
                    }

                    h2 {
                        font-size: 24px;
                        margin: 10px 0;
    
                        small {
                            font-size: 24px;
                            font-weight: 100;
                        }
                    }
                }
            }
            
        }
    }

    &-reactivate {
        width: 100%;
    }

    &-content {
        margin-top: -150px !important;
        max-width: 1440px;
        margin: 0 auto;

        @media (max-width: 768px){
            margin-top: -110px !important;
        }

        > div {
            height: auto !important;
        }
        > div > div {
            // display: block !important;
        }

        h1 {
            font-size: 48px;
            font-weight: bold;
            color: #CCCCCC;

            @media (max-width: 768px){
                font-size: 22px;
            }
        }
        p {
            font-size: 24px;
            font-weight: 500;
            color: #CCCCCC;

            @media (max-width: 768px){
                font-size: 16px;
            }
        }

        img {
            max-width: 100%;
        }
    }

    &-clubs {
        padding: 60px 0 40px 0;

        h3 {
          font-size: 18px;
          color: #cccccc; 
          width: 100%; 
          font-weight: bold;
        }

        img {
            margin: 0 auto;
            display: block;
        }
    }
}

.clubes {
    padding-top: 30px;
    margin: 80px 0;

    @media (max-width: 768px){
        margin: 30px 0;
    }
}