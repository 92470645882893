.Search {

    &-filter {
        position: relative;
        background: #45453D;
        width: 100%;
        margin-bottom: 50px;
        border-radius: 15px;
            

        input {
            position: relative;
            width: 100%;
            z-index: 3;
            color: #888;
            font-size: 24px;
            font-weight: bold;
            padding: 20px 20px 20px 80px;
            background: url(./pesquisa.svg) 20px center no-repeat;
            background-size: 35px;

            &::placeholder {
                color: #888;
            }

            @media screen and (max-width: 768px){
                font-size: 16px;
            }
        }

    }

}