.register-box {
    padding-left: 5%;
    padding-right: 5%;

    .checkbox-control {

        label {
            margin: 0;
            padding: 0;
        }

        input[type=checkbox] {
            appearance: none;
            -webkit-appearance: none;
            height: 0;
            width: 0;
            border: 0;
        }

        input[type=checkbox] + label {
            padding-left: 20px;
            position: relative;
            cursor: pointer;
        }
        
        input[type=checkbox] + label:after {
            transition: all 0.3s linear;
            position: absolute;
            left: 0;
            top: 0;
            width: 15px;
            height: 15px;
            box-shadow: inset 0px 0px 0px 2px #666;
            border-radius: 2px;
            content: '';
        }

        input[type=checkbox]:checked + label:after {
            box-shadow: inset 0px 0px 0px 20px #666;
        }

    }

}

.register-step {
    font-size: 10px;
    color: #707070;
    width: 6em;
    margin: 0;
	padding: 0;
}

.register-form {
    padding-bottom: 50px;
    padding-top: 50px;
    max-width: 400px;
    width: 100%;
    background-color: #D8D8D8;
    border-radius: 10px;
    margin: 50px auto;
    box-sizing: border-box;
}

.register-form-title {
    font-size: 20px;
    text-align: left;
    color: #707070;
    font-weight: bold;
    margin: 0;
	padding: 0;
}

.register-form-input {
    display: block;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #CBCBCB;
    color: #707070;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.register-form-submit {
    display: block;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #EA9900;
    color:white;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    width: 100%;

    
}

.register-checkbox {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: #707070 4px solid;
    
}

.register-texts {
    font-size: 10px;
    color: #707070;
}

.register-page {
    min-height: calc(100vh - 242px);
    padding: 0 10px;
    display: block;
}


.checkbox-control {
    display: flex;
    justify-content: center;
    align-items: center;
}

input:disabled {
    opacity: .6;
}