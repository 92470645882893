.loading-frame {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 130px;
    align-items: center;
}

.loading-stage {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    color: #fff;
    background: rgba(0,0,0,.85);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}