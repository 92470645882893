.WelcomeModal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 50px;
            top: 50px;
        }

        p {
            font-size: 48px;
            font-family: 'Raleway', sans-serif;
            font-weight: bold;
            color: #CCCCCC;
        }

        .btn-default {
            font-weight: bold;
            font-family: 'Raleway', sans-serif;
            color: #fff;
            font-size: 40px;
            padding: 15px 35px;
            background: #EA9900;
            border: 0;
            border-radius: 5px;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9997;
        /* Add the blur effect */
        filter: blur(8px);
        -webkit-filter: blur(8px);
        background: rgba(0,0,0,.8);
        backdrop-filter: blur(4px);
    }

    a.btn-close {
        font-size: 12px;
        color: #F7F7F7 !important;
        cursor: pointer;
        font-weight: 100;
        line-height: 30px;

        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: 768px){
        p {
            font-size: 24px;
        }
    }
}


.WelcomeModal.close-modal {
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}

@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
