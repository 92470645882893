.change-password {

    .login-form-title {
        padding: 0;
        margin: 0;
    }

    small {
        padding: 10px 0 20px 0;
        display: block;
        color: #9B9B9B;
        font-size: 12px;
    }

    
    form {
        p {
            padding: 10px 0 0px 0;
            display: block;
            color: #9B9B9B;
            font-size: 10px;
        }
    }

}