.profile {
    max-width: 500px;
    margin: 0 auto;
    min-height: calc(100vh - 192px);
    padding: 60px 10px;

    h2 {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .profileBox {
        border: 1px solid #454545;
        border-radius: 5px;
        margin-bottom: 40px;

        &--header {
            background: #454545;
            padding: 13px 20px;
            color: #fff;
        }

        &--item {
            padding: 13px 20px;
            color: #fff;
        }

        &--item + .profileBox--item {
            border-top: 1px solid #454545;
        }
    }

    .profileBox--item.flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            padding: 0;
            margin: 0;
        }
    }
}